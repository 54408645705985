<template>
    <ul v-if="!isEmpty" v-bind="$attrs">
        <li v-for="(item, index) in displayedItems" :key="`${randomId}_${item.id}`" :ref="item.ref">
            <slot name="item" :item="item" :index="index" />
        </li>
        <li v-if="showLoading">
            <slot name="loading">
                <div>{{ $t('common.loading') }}</div>
            </slot>
        </li>
    </ul>
    <div v-else-if="isEmpty">
        <slot name="empty" />
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

const TRIGGER_REF = 'TRIGGER_REF';

@Component
export default class InfiniteList extends Vue {
    /** @type {Array<{ id: number }>} */
    @Prop({ type: Array, required: true }) items;

    /** @type {boolean} */
    @Prop({ type: Boolean, required: false, default: false }) hasMore;

    /**
     * Truncate the list to the specified number of items.
     * If `null` or unset, the list will not be truncated.
     * @type {number | null}
     */
    @Prop({ required: false, default: null }) truncate;

    /**
     * The number of items left to display before loading more.
     * @type {number}
     */
    @Prop({ type: Number, required: false, default: 10 }) loadThreshold;

    /** @type {IntersectionObserver | null} */
    observer = null;

    /** @type {HTMLElement | null} */
    trigger = null;

    randomId = null;

    created() {
        this.randomId = Math.random().toString(36).substring(2, 15);
    }

    mounted() {
        this.observeTrigger();
    }

    beforeDestroy() {
        this.observer?.disconnect();
    }

    @Watch('items')
    @Watch('truncate')
    onItemsChange() {
        this.$nextTick(this.observeTrigger); //TODO: does this work?
    }

    /** @param entries {IntersectionObserverEntry[]} */
    onIntersect([entry]) {
        if (!entry.isIntersecting) return;
        this.observer.unobserve(this.trigger);
        this.trigger = null;
        this.$emit('load-more');
    }

    observeTrigger() {
        if (!this.hasMore) return;

        this.trigger = this.$refs[TRIGGER_REF]?.at(-1);
        if (!this.trigger) return;

        if (this.observer) this.observer.disconnect();
        this.observer = new IntersectionObserver(this.onIntersect);
        this.observer.observe(this.trigger);
    }

    /**
     * @returns {Array<{ id: number; ref?: TRIGGER_REF }>}
     */
    get displayedItems() {
        const items = this.items.slice(0, this.truncateLimit);

        const triggerItem = items[items.length - this.loadThreshold];
        if (triggerItem) triggerItem.ref = TRIGGER_REF;

        return items;
    }

    get showLoading() {
        return this.hasMore && this.items.length < this.truncateLimit;
    }

    get isEmpty() {
        return !this.items.length;
    }

    get truncateLimit() {
        return this.truncate ?? Infinity;
    }
}
</script>
